import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAlertNotification() {
  const toast = useToast()

  const successToast = (title, message) => {
    toast({
      component: ToastificationContent,
      props: {
        title,
        icon: 'CheckIcon',
        text: message,
        variant: 'success',
        hideProgressBar: true,
      },
    })
  }
  const errorToast = (title, message) => {
    toast({
      component: ToastificationContent,
      props: {
        title,
        icon: 'InfoIcon',
        text: message,
        variant: 'danger',
        hideProgressBar: true,
      },
    })
  }
  const notificationToast = (title, message) => {
    toast({
      component: ToastificationContent,
      props: {
        title,
        icon: 'BellIcon',
        text: message,
        variant: 'success',
        hideProgressBar: true,
      },
    })
  }

  return {
    successToast,
    errorToast,
    notificationToast,
  }
}
