import Swal from 'sweetalert2'

export default function useSweetAlertNotification() {
  const successSweetAlert = (title, text) => {
    Swal.fire({
      title,
      text,
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-success btn-sm',
      },
      confirmButtonText: 'Ok',
    })
  }
  const errorSweetAlert = (title, text) => {
    Swal.fire({
      title,
      text,
      icon: 'error',
      customClass: {
        confirmButton: 'btn btn-danger btn-sm',
      },
      confirmButtonText: 'Ok',
    })
  }
  return {
    successSweetAlert,
    errorSweetAlert,
  }
}
